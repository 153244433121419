






















import { Component, Vue, Prop } from "vue-property-decorator";
import { RedCapital, RedCapitalConfig, SelectedCountry } from "@/configuration";
import { isRutValid, rutParser } from "@/utils";

Vue.directive("rut", {
  bind(el, binding) {
    const inputEvents = ["keyup"];

    let formatter = (e: any) => {
      let value = e.target.value;
      e.target.value = rutParser(value);
    };
    inputEvents.forEach(event => {
      el.addEventListener(event, formatter);
    });
  }
});

@Component({
  data() {
    return {
      SelectedCountry,
      RedCapital,
      dni: "",
      isFocus: false,
      rules: {
        ruc: [
          (v: any) =>
            !!v || `${RedCapitalConfig(SelectedCountry).DniName} es requerido`,
          (v: any) =>
            !(v.length > 11 || v.length < 11) ||
            `${RedCapitalConfig(SelectedCountry).DniName} no válido`
        ],
        rut: [
          (v: any) =>
            !!v || `${RedCapitalConfig(SelectedCountry).DniName} es requerido`,
          (v: any) =>
            isRutValid(v) ||
            `${RedCapitalConfig(SelectedCountry).DniName} inválido`
        ]
      }
    };
  },
  watch: {
    dni() {
      // @ts-ignore
      this.onChange();
    },
    value(val) {
      if (SelectedCountry == "CL" || SelectedCountry == "PYME") {
        this.$data.dni = rutParser(val);
      } else {
        this.$data.dni = val;
      }
    }
  },
  created() {
    this.$data.dni = this.$props.value;
  },
  methods: {
    onChange() {
      this.$emit("input", this.$data.dni);
    }
  }
})
export default class DniField extends Vue {
  @Prop({ default: false }) disabled!: boolean;
  @Prop() value!: string;
  @Prop() label!: string;
  @Prop() prefix!: string;
  @Prop() suffix!: string;
}
