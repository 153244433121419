













































import { Component, Vue, Prop } from "vue-property-decorator";
import { RedCapital, RedCapitalConfig, SelectedCountry } from "@/configuration";
import { isRutValid, rutParser } from "@/utils";
import { Configuration } from "@/configuration";
import API from "@/api";
import Axios from "axios";
import { EventBus } from "@/main";
// @ts-ignore
import { rutInputDirective } from "vue-dni";

Vue.directive("rut", {
  bind(el, binding) {
    const inputEvents = ["keyup"];

    let formatter = (e: any) => {
      let value = e.target.value;
      e.target.value = rutParser(value);
    };

    inputEvents.forEach(event => {
      el.addEventListener(event, formatter);
    });
  }
});

@Component({
  data() {
    return {
      SelectedCountry,
      RedCapital,
      dni: "",
      isFocus: false,
      icon: null,
      color: "",
      rules: {
        ruc: [
          (v: any) =>
            !!v || `${RedCapitalConfig(SelectedCountry).DniName} es requerido`,
          (v: any) =>
            !(v.length > 11 || v.length < 11) ||
            `${RedCapitalConfig(SelectedCountry).DniName} no válido`
        ],
        rut: [
          (v: any) =>
            !!v || `${RedCapitalConfig(SelectedCountry).DniName} es requerido`,
          (v: any) =>
            isRutValid(v) ||
            `${RedCapitalConfig(SelectedCountry).DniName} inválido`
        ]
      }
    };
  },
  watch: {
    dni() {
      // @ts-ignore
      this.onChange();
    },
    value(val) {
      if (SelectedCountry == "CL" || SelectedCountry == "PYME") {
        this.$data.dni = rutParser(val);
      } else {
        this.$data.dni = val;
      }
    }
  },
  beforeMount() {
    EventBus.$on("dni-loading", (text: any) => {
      this.$data.icon = "cached";
      this.$data.color = "warning";
    });
    EventBus.$on("dni-success", (text: any) => {
      this.$data.icon = "done";
      this.$data.color = "success";
    });
    EventBus.$on("dni-error", (text: any) => {
      this.$data.icon = "clear";
      this.$data.color = "error";
    });
    EventBus.$on("dni-clean", (text: any) => {
      this.$data.icon = "";
      this.$data.color = "primary";
    });
    EventBus.$on("blur", (dni: any) => {
      // @ts-ignore
      this.log(0, dni);
    });
  },
  created() {
    this.$data.dni = this.$props.value;
  },
  // beforeDestroy() {
  // 	EventBus.$off(['dni-loading', 'dni-success', 'dni-error', 'dni-clean', 'blur'])
  // },
  methods: {
    onChange() {
      this.$emit("input", this.$data.dni);
    },
    log(el: any, val: any) {
      if (isRutValid(val.toUpperCase()) && val.length == 12) {
        Axios.get(`${Configuration.api.sii}${val.toUpperCase()}`)
          .then((res: any) => {
            EventBus.$emit("snack-success", "Rut válido");
            const payload = {
              dni: val.toUpperCase(),
              name: res.data.razon_social,
              field: el
            };
            EventBus.$emit("blur-response", payload);
          })
          .catch((error: any) => {
            EventBus.$emit("snack-error", error.response.data.error.message);
          });
      }
    }
  }
})
export default class DniField extends Vue {
  @Prop() value!: string;
  @Prop() label!: string;
  @Prop() prefix!: string;
  @Prop() suffix!: string;
  @Prop() unique!: string;
}
