














































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  RedCapital,
  SelectedCountry,
  TitleTemplatePublic,
  Configuration
} from "@/configuration";
import { EventBus } from "@/main";
import API from "@/api";
import axios from "axios";
import DniPersona from "@/components/DniFieldLanding.vue";
import DniEmpresa from "@/components/DniFieldBusinessLanding.vue";

@Component({
  metaInfo() {
    return {
      title: "Landing",
      titleTemplate: TitleTemplatePublic,
      htmlAttrs: {
        // @ts-ignore
        lang: RedCapital[SelectedCountry].Lang
      }
    };
  },
  data() {
    return {
      RedCapital,
      SelectedCountry,
      validtop: true,
      validbottom: false,
      dialog: false,
      canPress: true,
      name: "",
      lastname: "",
      dni: "",
      businessName: "",
      businessDni: "",
      email: "",
      comment: "",
      landing: "BCP",
      via:
        this.$route.query.via != null ? this.$route.query.via : "Sin campaña",
      phone: "",
      amount: "",
      rules: {
        name: [
          (v: any) => !!v || "Ingrese nombre",
          (v: any) =>
            v.length <= 255 || "Nombre no puede exceder los 255 caracteres."
        ],
        phone: [
          (v: any) => !/[^\d]/gim.test(v) || "Tiene que ser solo numeros"
        ],
        email: [
          (v: any) => !!v || "Ingrese correo electrónico",
          (v: any) => /.+@.+/.test(v) || "La dirección debe ser válida"
        ],
        rut: [
          (v: any) =>
            // @ts-ignore
            !!v || `${RedCapital[SelectedCountry].DniName} es requerido`,
          (v: any) =>
            /^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/.test(v) ||
            // @ts-ignore
            `${RedCapital[SelectedCountry].DniName} invalido Ej: 12.345.678-9`
        ],
        ruc: [
          (v: any) =>
            // @ts-ignore
            !!v || `${RedCapital[SelectedCountry].DniName} es requerido`,
          (v: any) =>
            v.toString().length != 11 ||
            // @ts-ignore
            `${RedCapital[SelectedCountry].DniName} no válido`
        ]
      }
    };
  },
  methods: {
    async validateTop(response) {
      this.$data.canPress = false;
      API.landing
        .bcp(
          this.$data.name,
          this.$data.lastname,
          this.$data.dni,
          this.$data.businessName,
          this.$data.businessDni,
          this.$data.email,
          this.$data.landing,
          this.$data.via,
          this.$data.phone,
          this.$data.amount,
          this.$data.comment
        )
        .then((data: any) => {
          this.$data.dialog = true;
          setTimeout(() => {
            this.$router.push({
              path: "/"
            });
          }, Configuration.Redirection.timeout);
        })
        .catch((error: any) => {
          this.$data.canPress = true;
          this.$data.error = "Error";
          if (error.message.nombre != null) {
            EventBus.$emit("snack-error", error.message.nombre[0]);
          }
          if (error.message.mail != null) {
            EventBus.$emit("snack-error", error.message.mail[0]);
          }
        });
    },
    async validateBottom(response) {
      this.$data.canPress = false;
      API.landing
        .bcp(
          this.$data.name,
          this.$data.lastname,
          this.$data.dni,
          this.$data.businessName,
          this.$data.businessDni,
          this.$data.email,
          this.$data.landing,
          this.$data.via,
          this.$data.phone,
          this.$data.amount
        )
        .then((data: any) => {
          alert(data);
          this.$data.dialog = true;
          setTimeout(() => {
            this.$router.push({
              path: "/"
            });
          }, Configuration.Redirection.timeout);
        })
        .catch((error: any) => {
          alert(error);
          this.$data.canPress = true;
          this.$data.error = "Error";
          if (error.message.nombre != null) {
            EventBus.$emit("snack-error", error.message.nombre[0]);
          }
          if (error.message.mail != null) {
            EventBus.$emit("snack-error", error.message.mail[0]);
          }
        });
    }
  },
  components: {
    DniEmpresa,
    DniPersona
  }
})
export default class Landing extends Vue {}
